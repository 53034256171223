<template>
  <div class="container-fluid">
    <div class="mx-4 mt-5 overflow-hidden card card-body shadow-blur text-primary bg-white ">
      <div class="row  gx-4 flex-wrap justify-content-center">
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-0">{{ company?.name }}</h5>
          </div>
        </div>
        <div
            class="col-12 col-sm mt-3 mt-sm-0 d-flex gap-3 flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-end align-items-center">
          <div class="text-center bg-success rounded-3 d-flex flex-nowrap px-3 align-items-center text-white gap-1">
            <strong>{{ allDoneAmount }}</strong><span class="d-none d-md-block"> Vollständig</span></div>
          <div class="text-center bg-warning rounded-3 d-flex flex-nowrap px-3 align-items-center text-white gap-1">
            <strong>{{ allUndoneAmount }}</strong> <span class="d-none d-md-block"> Unvollständig</span></div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="mt-4 row m-1">
        <div class="mb-4 card ">
          <div class="d-flex justify-content-between p-3 align-items-center">
            <div class="pb-0">
              <h6 class="mb-0">Company Beiträge</h6>
            </div>
            <div class=" pb-0 d-flex gap-4 flex-wrap justify-content-end">
              <div class="order-sm-first">
                <soft-input
                    v-model="searchInput"
                    placeholder="Suchen"
                    icon="fa fa-search"
                    iconDir="right"
                    class="m-0"/>
              </div>
              <soft-button
                  v-if="toBeDeleted.length > 0 && multiSelection"
                  class="mx-0 mx-sm-2 order-last order-sm-first"
                  color="dark"
                  size="md"
                  @click="multiDeleteImages">
                Auswahl löschen
              </soft-button>
              <soft-button
                  v-if="postList.length > 0"
                  :color="multiSelection ? 'secondary' : 'light'"
                  size="md"
                  @click="multiSelectClick"
              >
                Multi auswahl
              </soft-button>
            </div>
          </div>
          <div class="p-4 card-body">
            <div class="row g-3">
              <div class="text-center q-mt-md" v-if="searchInput !== '' && searchList.length === 0">
                Zu der Suchanfrage gab es kein Ergebnis.
              </div>
              <post-card
                  v-for="(item, idx) in sortedPostList" :key="idx"
                  :loading="loadingData"
                  :skeleton-posts=2
                  :title="item.text"
                  :id="item.id"
                  :link-path="'/single-post?postId='+ item.id +'&c='+this.activeId"
                  :status-label="statusConverterLabel(item)"
                  :label-color="stateConverterColor(item)"
                  :label="stateConverterName(item)"
                  :multi-selections="multiSelection"
                  :description="item.hashtags"
                  :publication-date="item.publicationDate"
                  :project-id="item.id"
                  :instagram="item.instagramText"
                  :facebook="item.facebookText"
                  :linkedin="item.linkedinText"
              ></post-card>
              <div v-if="postList.length === 0" class="text-center q-mt-md">
                Es gibt noch keine Posts
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import api, {apiGet} from "@/api/api";
import SoftButton from "@/components/SoftButton.vue";
import SoftInput from "@/components/SoftInput.vue";
import PostCard from "@/components/PostCard.vue";
import moment from "moment/moment";
import ProfileInfoCard from "@/views/easy-social/ProfileInfoCard.vue";


export default {
  name: "ImageGallery",
  components: {
    SoftInput,
    SoftButton,
    SoftSwitch,
    ProfileInfoCard,
    PostCard
  },
  data() {
    return {
      showMenu: false,
      toBeDeleted: [],
      searchInput: '',
      loadingData: false,
      multiSelection: false,
      company: {
        name: ''
      },
      postList: [],
    };
  },
  async mounted() {
    this.$store.state.showNavbarMini = false;
    this.$store.state.isAbsolute = true;
    const sidenav_show = document.querySelector(".g-sidenav-show");
    sidenav_show.classList.remove("g-sidenav-hidden");
    sidenav_show.classList.add("g-sidenav-pinned");
    sidenav_show.children[0].style.setProperty("background-color", "white", "important");

    setNavPills()
    setTooltip(this.$store.state.bootstrap);
    await this.setCompany()

  },
  computed: {
    activeId() {
      return parseInt(this.$store.state.activeId);
    },
    searchList() {
      return this.searchInput === '' ? this.postList : this.postList?.filter((item) => (item.text?.toLowerCase().match(this.searchInput.toLowerCase())))
    },
    sortedPostList() {
      return [...this.searchList.filter((x) => x.status !== 'DONE'), ...this.searchList.filter((x) => x.status === 'DONE')]
    },
    allDoneAmount() {
      return this.postList?.filter((x) => x.text !== '').length
    },
    allUndoneAmount() {
      return this.postList?.filter((x) => (x.text === '')).length
    },
  },
  methods: {
    async setCompany() {
      let resp;
      this.loadingData = true;

      if (this.$store.state.activeId === undefined || this.$store.state.activeId === null) {
        resp = await apiGet(localStorage.getItem('adminId'),'/post?companyId=' + localStorage.getItem('companyId'))
      } else {
        resp = await apiGet(localStorage.getItem('adminId'),'/post?companyId=' + this.activeId)
      }


      this.postList = resp.data.posts.map((x) => {
        return {
          ...x,
          text: decodeURI(x.text),
          hashtags: decodeURI(x.hashtags),
          publicationDate: decodeURI(moment(x.publicationDate).format('DD.MM.yyyy')),
        }
      })

      this.postList = this.postList.reverse()
      this.company = resp.data.company
      this.loadingData = false;
    },

    multiSelectClick() {
      if (this.multiSelection) {
        this.postList.forEach((element) => element.selected = false)
      } else {
        this.toBeDeleted = []
      }
      this.multiSelection = !this.multiSelection
    },
    addToBeDeletedList(id) {
      if (this.toBeDeleted.includes(id)) {
        this.toBeDeleted = this.toBeDeleted?.filter((x) => x !== id)
      } else {
        this.toBeDeleted.push(id);
      }
    },
    async multiDeleteImages() {
      await api.post('/post/delete', this.toBeDeleted)
          .then(() => {
            this.postList = this.postList?.filter((x) => {
              return !this.toBeDeleted.includes(x.id)
            })
            this.toBeDeleted = []
            this.multiSelection = false
          })
          .catch((e) => {
            console.error(e)
          })
    },
    statusConverterLabel(item) {
      return item.status === 'OPEN' ? 'Offen' : item.status === 'IN_POGRESS' ? 'In Bearbeitung' : item.status === 'DONE' ? 'Offen' : item.status === 'IN_EXAMINATION' ? 'Zur Kundenfreigabe' : item.status === 'APPROVED' ? 'Offen' : item.status === 'DECLINED' ? 'Offen' : 'Fehler'
    },
    stateConverterName(item) {
      const nbr = item.text !== '' ? 0 : item.text !== '' ? 1 : item.text === '' ? 1 : 2
      return nbr === 0 ? 'Vollständig' : nbr === 1 ? 'Unvollständig' : nbr === 2 ? 'Fertig' : 'Fehler'
    },
    stateConverterColor(item) {
      const nbr = item.text !== '' ? 0 : item.text !== '' ? 1 : item.text === '' ? 1 : 2
      return nbr === 0 ? 'success' : nbr === 1 ? 'warning' : 'dark'
    },
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
